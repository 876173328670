@font-face {
  font-family: 'Calibri';
  src: url('./fonts/Calibri.ttf') format('truetype');
}

@font-face {
  font-family: 'Impact';
  src: url('./fonts/Impact.ttf') format('truetype');
}

@font-face {
    font-family: 'Franklin Gothic';
    src: url('./fonts/Franklin Gothic.ttf') format('truetype');
}

@font-face {
    font-family: 'Garamond';
    src: url('./fonts/Garamond.ttf') format('truetype');
}

@font-face {
    font-family: 'Comic Sans MS';
    src: url('./fonts/Comic Sans MS.ttf') format('truetype');
}

@font-face {
    font-family: 'Courier New';
    src: url('./fonts/Courier New.ttf') format('truetype');
}

@font-face {
    font-family: 'Rockwell';
    src: url('./fonts/Rockwell.ttc') format('truetype');
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica.ttc') format('truetype');
}

@font-face {
    font-family: 'Futura';
    src: url('./fonts/Futura.ttc') format('truetype');
}

@font-face {
    font-family: 'Bodoni';
    src: url('./fonts/Bodoni.ttc') format('truetype');
}

@font-face {
    font-family: 'Frutiger';
    src: url('./fonts/Frutiger.ttf') format('truetype');
}

@font-face {
    font-family: 'Myriad Pro Regular';
    src: url('./fonts/Myriad Pro Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham.ttf') format('truetype');
}