.shepherd-target {
    border: indigo solid 2px;
}

.shepherd-title {
    font-size: 1.5em;
    font-weight: bold;
}

.shepherd-cancel-icon {
    font-size: 1.5em;
    top: 0;
    right: 0;
    float: right;
    display: inline;
    position: absolute;
    padding-right: 8px;
}